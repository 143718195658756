/* eslint-disable vue/no-unused-components */
<template>
  <div :class="{ 'container-fluid': !hideHeader }">
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des doublons</h4>
        </div>
        <div class="btn-group">
          <a href="" @click.prevent="scannerDoublons" class="style_btn btn btn-danger"
            >Scanner des doublons</a
          >
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="row mb-4" v-if="viewMode == 'card'">
            <div
              class="col-sm-4 col-lg-3 mb-3"
              v-for="de in mxFilteredItems"
              :key="de.id"
            >
              <div class="style_profil_card p-3 pt-3">
                <div
                  @click.stop="showDemandeur(de)"
                  class="d-flex justify-content-center mb-3"
                >
                  <azoli-status-badge :de="de" :key="'_statut_c_' + de.id" />
                </div>
                <div class="mx-auto">
                  <a href="#">
                    <div
                      @click="uploadFile(de)"
                      class="rounded-circle style_bg_img border"
                      :style="`background-image: url(${
                        de.fullPhotoUrl != null
                          ? de.fullPhotoUrl
                          : '/assets/img/profilMan.jpg'
                      }); height: 100px; width: 100px;`"
                    ></div>
                  </a>
                </div>
                <div class="py-3 text-center border-bottom">
                  <div
                    class="d-flex no-wrap justify-content-center align-items-center text-muted"
                  >
                    <PBadge
                      :severity="de.estPlace ? 'success' : 'danger'"
                      :value="
                        (de.estPlace ? 'PLACE' : 'NON PLACE') +
                        ' | ' +
                        de.nombreMER +
                        ' MER'
                      "
                    />
                  </div>
                  <h6 class="my-1">
                    <a href="#" class="text-dark text-uppercase"
                      >{{ de.nom }} {{ de.prenom }}</a
                    >
                  </h6>

                  <small v-if="de.validePar" class="text-muted text-bold"
                    >Validé par: {{ de.validePar.nom }}
                    {{ de.validePar.prenom }}
                    <span class="text-uppercase"
                      >, le {{ de.validationDate | formatDate }}</span
                    ></small
                  >
                  <small v-else-if="de.creePar" class="text-muted"
                    >Réalisé par: {{ de.creePar.nom }}
                    {{ de.creePar.prenom }}
                    <span class="text-uppercase"
                      >, le {{ de.lastLogin | formatDate }}</span
                    ></small
                  >
                  <small v-else class="text-muted"
                    >Réalisé par:
                    <span class="text-bold">LUI-MEME</span>
                    <span class="text-uppercase"
                      >, le {{ de.lastLogin | formatDate }}</span
                    ></small
                  >
                </div>
                <div class="d-flex justify-content-center mt-2">
                  <!-- <a class="btn btn-success rounded-0 btn-sm me-1" ><i class="bi bi-pencil-square"></i></a> -->
                  <a
                    class="btn btn-info rounded-0 btn-sm me-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Consulter et modifier"
                    @click.stop="showDemandeur(de)"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    title="Générer PDF"
                    @click.stop.prevent="downloadFiche(de)"
                    ><i class="bi bi-printer"></i
                  ></a>
                  <a
                    @click.stop.prevent="remplirFiche(de)"
                    title="Saisir la fiche"
                    class="btn btn-warning rounded-0 btn-sm me-1"
                    ><i class="bi bi-file-earmark-check"></i
                  ></a>
                  <button
                    :title="'Valider la fiche/cv'"
                    @click.prevent="showDossierValidationDialog(de)"
                    class="btn btn-light btn-sm me-1"
                  >
                    <i
                      :class="{
                        'text-danger': de.status == 'VALIDE',
                      }"
                      class="bi bi-clipboard-check"
                    ></i>
                  </button>
                  <button
                    title="Prise en charge"
                    @click.prevent="priseEnCharge(de)"
                    class="btn btn-primary btn-sm me-1"
                  >
                    <i class="bi bi-file-earmark-medical"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap justify-content-center align-items-center mt-3"
              >
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div v-else class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(status)="{ item: de }">
                  <div class="d-flex justify- mb-3">
                    <azoli-status-badge
                      :de="de"
                      v-tooltip.top="
                        `${
                          de.validePar != null
                            ? 'NPI validé ' + de.validePar.nomPrenom
                            : 'Non validé'
                        }`
                      "
                    />
                  </div>
                </template>
                <template #cell(createdDate)="{ item: de }">
                  <div
                    v-tooltip.top="$dayjs(de.createdDate).format('DD/MM/YYYY à HH:mm')"
                    class="d-flex justify- mb-3"
                  >
                    {{ $dayjs(de.createdDate).format("DD/MM/YYYY") }}
                  </div>
                </template>
                <template #cell(tauxRemplissageProfil)="{ item: de }">
                  {{ de.tauxRemplissageProfil }}%
                </template>
                <template #cell(estSuivi)="{ item }">
                  <div class="position-relative">
                    <PTag
                      v-tooltip.left="
                        item.estSuiviPar != null
                          ? 'Suivi par ' + item.estSuiviPar
                          : 'Pas encore suivi'
                      "
                      rounded
                      :severity="
                        item.estSuivi
                          ? item.estAutoAffecte
                            ? 'info'
                            : 'primary'
                          : 'danger'
                      "
                      size="large"
                      >{{
                        item.estSuivi
                          ? item.estAutoAffecte
                            ? "Auto.Affecté"
                            : "Affecté"
                          : "Non.Affecté"
                      }}</PTag
                    >
                  </div>
                </template>
                <template #cell(statutDoublon)="data">
                  <ClassifierDoublon v-model="data.item" />
                </template>
                <template #cell(modeDeclationDoublon)="data">
                  <span v-if="data.item.modeDeclationDoublon == null">N/A</span>
                  <PBadge
                    v-else
                    :value="data.item.modeDeclationDoublon"
                    :severity="
                      data.item.modeDeclationDoublon != 'AUTO' ? 'success' : 'warning'
                    "
                  ></PBadge>
                </template>
                <template #cell(npi)="{ item: de }">
                  <span v-if="de.npiValide == null">{{ de.npi }}</span>
                  <span
                    v-else
                    class="text-uppercase"
                    v-tooltip.top="
                      `NPI validé ${de.validePar != null ? de.validePar.nomPrenom : ''}`
                    "
                    style="font-size: 1.2rem"
                    v-badge.primary="de.npiValide ? 'Ok' : ''"
                    >{{ de.npi }}</span
                  >
                </template>
                <template #cell(nom)="{ item: de }">
                  <span class="text-uppercase">{{ de.nom }}</span>
                </template>
                <template #cell(prenom)="{ item: de }">
                  <span class="text-uppercase">{{ de.prenom }}</span>
                </template>
                <template #cell(creePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.creePar"
                    >{{ de.creePar.nom }} {{ de.creePar.prenom }}</span
                  >
                </template>
                <template #cell(validePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.validePar"
                    >{{ de.validePar.nom }} {{ de.validePar.prenom }}</span
                  >
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <PTabPanel header="CV">
                        <DemandeurMonCv
                          v-if="activeTab == 0"
                          :checkHasCv="false"
                          :demandeurId="row.item.id"
                        />
                      </PTabPanel>
                      <PTabPanel header="Fiche d'enquête">
                        <FicheAzoli
                          v-if="row.item.estPPI && activeTab == 1"
                          :demandeurId="row.item.id"
                        />
                        <div v-else>Ce demandeur n'a pas de fiche d'enquête</div>
                      </PTabPanel>
                    </PTabView>
                  </div>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      v-tooltip.top="'Voir les détails'"
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>

                    <button
                      title="Valider la fiche/cv"
                      @click.prevent="showDossierValidationDialog(row.item)"
                      class="btn btn-light btn-sm"
                    >
                      <i
                        :class="{
                          'text-danger': row.item.status == 'VALIDE',
                        }"
                        class="bi bi-clipboard-check"
                      ></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div
                  class="d-flex flex-column mb-2 align-items-center justify-content-center"
                >
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.ce.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CDetailViewer from "@/components/common/CDetailViewer.vue";

import DemandeurMonCv from "@/views/espace/demandeur/DemandeurMonCv.vue";
import FicheAzoli from "@/views/espace/conseiller/DemandeurDetailAzoli.vue";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import ClassifierDoublon from "../common/doublon/ClassifierDoublon.vue";

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FicheAzoli,
    DemandeurMonCv,
    CDetailViewer,
    AzoliStatusBadge,
    ClassifierDoublon,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTab: 0,
      detailViewerActiveTab: 0,
      showAdvancedFilter: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      innerTabs: [{ component: null, subtitle: "", extraQuery: "" }],
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      statutPecOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "PEC" },
        { value: false, label: "NPEC" },
      ],
      statutPec: null,
      statutPlacementOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "PLACE" },
        { value: false, label: "NON PLACE" },
      ],
      statutPlacement: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: "VULNERABLE", label: "VULNERABLE" },
        { value: "NON_VULNERABLE", label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des demandeurs d'emploi",
                subtitle: "Liste des demandeurs d'emploi",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "demandeurs/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des bénéficiaires Azoli.xlsx",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                fetcherMethod: "demandeurs/exportToExcel",
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["nomPrenom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nomPrenom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune.nom",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement.nom",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Nom & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement.nom",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville.nom",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nomPrenom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "azoli.typeEmploi",
          key: "label",
          valueKey: "value",
          value: "",
          label: "Type D'emploi recherché",
          dropdown: true,
          // url: "/romes",
          items: [
            { value: "SALARIE", label: "SALARIE" },
            { value: "AUTO_EMPLOI", label: "AUTO_EMPLOI" },
            { value: "INDIFERENT", label: "INDIFERENT" },
          ],
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "antenne.id",
          key: "intitule",
          valueKey: "id",
          value: "",
          label: "Antenne",
          dropdown: true,
          url: "/antennes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "estSuiviPar",
          key: "nomPrenom",
          valueKey: "nomPrenom",
          value: "",
          label: "Suivi par agent",
          dropdown: true,
          url: "/agents",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "creePar.id",
          key: "nomPrenom",
          valueKey: "id",
          value: "",
          label: "Inscrit par agent",
          dropdown: true,
          url: "/agents",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "validePar.id",
          key: "nomPrenom",
          valueKey: "id",
          value: "",
          label: "Validé par agent",
          dropdown: true,
          url: "/agents",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "affectabilite",
          key: "value",
          valueKey: "label",
          value: "",
          label: "Affectabilité du DE",
          dropdown: true,
          items: [
            { value: "AFFECTABLE", label: "AFFECTABLE" },
            { value: "NON_AFFECTABLE", label: "NON AFFECTABLE" },
          ],
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
        {
          column: "statutAffectation",
          key: "label",
          valueKey: "value",
          value: "",
          label: "Statut d'affectation du DE",
          dropdown: true,
          items: [
            { value: "ACTIF", label: "AFFECTE" },
            { value: "AUTO_ACTIF", label: "AUTO AFFECTE" },
            { value: "NON_AFFECTE", label: "NON AFFECTE" },
          ],
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      activeDemandeur: {},
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli, // écrase id du demandeur par id azoli
          id: val.id, //remettre Id du demandeur
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
    extraQueryArg() {
      this.queryStatistics();
    },
    // dateDeb(){
    //   this.queryStatistics()
    // },
    // dateFin(){
    //   this.queryStatistics()
    // }
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    statisticsQueryParams() {
      return this.mxBuildQueryParams();
    },
    tableFields() {
      let fields = [
        "#",
        // 'index',
        { key: "npi", label: "NPI" },
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "departement.nom", label: "Département" },
        { key: "commune.nom", label: "Commune" },
      ];

      if (this.$can("seeAffectationStatus", "Azoli")) {
        fields.push({ key: "estSuivi", label: "Affectation" });
      }

      fields = fields.concat([
        { key: "tauxRemplissageProfil", label: "Taux" },
        { key: "status", label: "Statut" },
        { key: "statutDoublon", label: "Décision" },
        { key: "modeDeclationDoublon", label: "ModeDec" },
        { key: "scoreValidite", label: "ScoreVal" },
        { key: "createdDate", label: "Inscrit le" },
        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ]);

      return fields;
    },
    parDepartementEnregistresTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parCommuneEnregistresTableFields() {
      return [
        // 'index',
        { key: "commune", label: "Commune" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parAeEnregistresTableFields() {
      return [
        // 'index',
        { key: "ae", label: "AE" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "femmesCount", label: "Nombre femmes" },
        { key: "enfantsCount", label: "Nombre enfants" },
        // { key: 'total', label: 'Total' },
      ];
    },
    parDepartementEnregistresTableData() {
      return this.parDepartementEnregistres;
    },
    parCommuneEnregistresTableData() {
      return this.parCommuneEnregistres;
    },
    parAeEnregistresTableData() {
      return this.parAeEnregistres;
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableData() {
      return this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
    }),
    getStatutDoublonLabel(item) {
      return item.statutDoublon == null
        ? "N/A"
        : item.statutDoublon
        ? "ACCEPTE"
        : "ISOLE";
    },
    getStatutDoublonColor(item) {
      return item.statutDoublon == null
        ? "light"
        : item.statutDoublon
        ? "primary"
        : "danger";
    },
    scannerDoublons() {
      this.$refs.doublonScanner.show();
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: {
          userRole: this.$route.params.userRole,
          demandeurId: de.id,
        },
      });
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) {
        this.$toast.error(
          "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
          {
            position: "top-right",
            duration: 8000,
          }
        );
        return;
      }
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    submitQuery() {
      this.mxSubmitQuery();
      this.$refs.deStatistic.reload();
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "demandeur",
        search: this.search,
        fetcherMethod: "demandeur/fetchDemandeurDoublons",
        dataGetter: "demandeur/demandeurs",
        paginationGetter: "demandeur/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("categorieDemandeur", this.demandeurType);
          paramPlus = this.mxAddQueryParam(
            "vulnerabilite",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("sexe", this.sexeDe, paramPlus);
          paramPlus = this.mxAddQueryParam("estPriseEnCharge", this.statutPec, paramPlus);
          paramPlus = this.mxAddQueryParam("estPlace", this.statutPlacement, paramPlus);

          let params = `${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    formatToDataTable(data, { keyName }) {
      let summary = {};
      return Object.keys(data).map((k) => {
        let { key, M = 0, F = 0, total = 0 } = data[k];
        summary.M += M;
        summary.F += F;
        summary.total += total;
        if (data[k]["TOTAL"] == undefined) {
          return {
            [keyName]: key,
            M,
            F,
            total,
          };
        } else {
          return {
            [keyName]: "TOTAL",
            ...summary,
          };
        }
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    downloadFiche(de) {
      // FileService.downloadFile(`demandeurs${de.id}/pdf`, `fichier_${de.id}.pdf`).then(()=>{})
      // this.downloadAzoli(de).then(()=>{})
      this.fetchDossier({
        demandeurId: de.id,
      }).then(() => {
        console.log(this.$refs.html2Pdf);
        this.$refs.html2Pdf.generatePdf();
      });
    },
    editDemandeur(demandeur) {
      console.log(demandeur);
      this.activeDemandeur = { ...demandeur };
      this.$router.push({ name: "espace.ce.de.edit" });
    },
    showDemandeur(demandeur) {
      this.activeDemandeur = { ...demandeur };
      this.$refs.detailViewer.show();
    },
    remplirFiche(demandeur) {
      if (!demandeur.estPPI) {
        this.$toast.info(
          "Vous ne pouvez pas remplir de fiche pour ce demandeur INSTRUIT.",
          {
            position: "top-right",
            duration: 8000,
          }
        );
      } else {
        this.$router.push({
          name: "espace.ce.azoli.edit",
          params: { demandeurId: demandeur.id },
        });
      }
    },
    async showDossierValidationDialog(demandeur) {
      this.activeDemandeur = { ...demandeur };
      this.$refs.dossierValidator.show();
    },
    async validerFiche(demandeur) {
      if (demandeur.status == "VALIDE") {
        this.$toast.info(demandeur.estPPI ? "Fiche déjà validée!" : "CV déjà validé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      if (demandeur.status != "TERMINE") {
        this.$toast.error(demandeur.estPPI ? "Fiche non terminée" : "CV non terminé", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      await this.$confirm.require({
        group: "confirmDialog",
        message: demandeur.estPPI
          ? `Voulez-vous vraiment valider  la fiche Azoli de ${demandeur.nom} ${demandeur.prenom}?`
          : `Voulez-vous vraiment valider le CV de ${demandeur.nom} ${demandeur.prenom}?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.validerDossier({
            ...demandeur,
            status: "VALIDE",
          }).then((data) => {
            console.log(data);
            this.$refs.deStatistic.reload();
            this.$toast.success("Fiche validée avec succès!", {
              position: "top-right",
              duration: 10000,
            });
          });
        },
      });
    },
    async destroyDemandeur(demandeur) {
      console.log(demandeur);
      await this.$confirm.require({
        group: "confirmDialog",
        message: "Voulez-vous vraiment supprimer cet élément?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDemandeur(demandeur);
        },
      });
    },
    updateOrCreateDemandeur(demandeur) {
      console.log(demandeur);
      this.createOrUpdateDemandeur(demandeur).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
